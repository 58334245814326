/* TIPTAP */
.ProseMirror pre {
  border-radius: 0.5rem;
  color: var(--color-text-primary);
  padding: 0.75rem 1rem;
}
.ProseMirror pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.ProseMirror pre .hljs-comment,
.ProseMirror pre .hljs-quote {
  color: var(--color-text-secondary);
}
.ProseMirror pre .hljs-variable,
.ProseMirror pre .hljs-template-variable,
.ProseMirror pre .hljs-attribute,
.ProseMirror pre .hljs-tag,
.ProseMirror pre .hljs-name,
.ProseMirror pre .hljs-regexp,
.ProseMirror pre .hljs-link,
.ProseMirror pre .hljs-name,
.ProseMirror pre .hljs-selector-id,
.ProseMirror pre .hljs-selector-class {
  color: var(--color-text-error-primary);
}
.ProseMirror pre .hljs-number,
.ProseMirror pre .hljs-meta,
.ProseMirror pre .hljs-built_in,
.ProseMirror pre .hljs-builtin-name,
.ProseMirror pre .hljs-literal,
.ProseMirror pre .hljs-type,
.ProseMirror pre .hljs-params {
  color: var(--color-text-warning-primary);
}
.ProseMirror pre .hljs-string,
.ProseMirror pre .hljs-symbol,
.ProseMirror pre .hljs-bullet {
  color: var(--color-text-success-primary);
}
.ProseMirror pre .hljs-title,
.ProseMirror pre .hljs-section {
  color: var(--color-text-brand-primary);
}
.ProseMirror pre .hljs-keyword,
.ProseMirror pre .hljs-selector-tag {
  color: var(--color-text-ai-primary);
}
.ProseMirror pre .hljs-emphasis {
  font-style: italic;
}
.ProseMirror pre .hljs-strong {
  font-weight: 700;
}
